#reader, #reader__header_message{
    border:none !important;
}
#reader__scan_region img {
  display: none;
}
.qr-button,
#html5-qrcode-button-camera-permission,
#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop {
  /* display: none; */
  /* background: blue; */
  border: none;
  padding: 20px;
  border-radius: 18px;
}

#reader__dashboard_section_csr,
#html5-qrcode-anchor-scan-type-change,
#html5-qrcode-button-camera-stop {
  display: none !important;
}

.qr-button-container{
    width:100%; 
    text-align: center;
}
.qr-button {
    margin: 0 auto !important;
}